/* eslint-disable complexity */
define([
    'santa-components',
    'lodash',
    'prop-types',
    'coreUtils',
    'components/components/bootstrap/balata/balata'
], function (
    santaComponents,
    _,
    PropTypes,
    coreUtils,
    balata
) {
    'use strict';
    const bgUtils = coreUtils.containerBackgroundUtils;
    const consts = coreUtils.mediaConsts;
    const {ACTION_TYPES} = coreUtils.siteConstants;
    const getVolumeAnimationDefault = () => ({value: null, animation: null});

    /**
     * Look for media data in compDesign nad fallback to compData
     * @param {object} props
     * @returns {{}}
     */
    function getMediaData(props) {
        return _.get(props.compDesign, ['background', 'mediaRef'], _.get(props.compData, ['background', 'mediaRef'])) || {};
    }

    /**
     * Look for image data in media data
     * @param {object} media
     * @returns {{}}
     */
    function getImageData(media) {
        return media.mediaType === 'WixVideo' ? media.posterImageRef : media;
    }

    /**
     * Look for the background in compDesign and fallback to compData
     * @param {object} props
     * @returns {{}}
     */
    function getBackgroundData(props) {
        return _.get(props.compDesign, ['background'], _.get(props.compData, ['background'])) || {};
    }

    /**
     * Whether we're in editor viewing env
     * @param {Object} props
     * @return {boolean}
     */
    function isEditorMode(props) {
        return props.componentViewMode === 'editor';
    }

    return {
        propTypes: _.defaults({
            animations: santaComponents.santaTypesDefinitions.animations.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            compDesign: santaComponents.santaTypesDefinitions.Component.compDesign,
            compStaticBehaviors: santaComponents.santaTypesDefinitions.Component.compStaticBehaviors,
            isInSeo: santaComponents.santaTypesDefinitions.isInSeo,
            bgStyle: PropTypes.object,
            onClick: PropTypes.func,
            shouldRenderSrc: santaComponents.santaTypesDefinitions.Media.shouldRenderSrc,
            renderParts: santaComponents.santaTypesDefinitions.Media.renderParts,
            playbackFormat: santaComponents.santaTypesDefinitions.Media.playbackFormat,
            playbackConfig: santaComponents.santaTypesDefinitions.Media.playbackConfig,
            playbackUrl: santaComponents.santaTypesDefinitions.Media.playbackUrl,
            mediaAspect: santaComponents.santaTypesDefinitions.SiteAspects.mediaAspect.isRequired,
            isPlayingAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isPlayingAllowed.isRequired,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode.isRequired,
            canVideoPlayInline: santaComponents.santaTypesDefinitions.Media.canVideoPlayInline,
            enableMaskedVideo: santaComponents.santaTypesDefinitions.Media.enableMaskedVideo,
            maskPosterFallback: santaComponents.santaTypesDefinitions.Media.maskPosterFallback

        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(balata)),

        getInitialState() {
            this.isInViewport = false;
            this.mediaAPI = null;
            this.volumeAnimation = getVolumeAnimationDefault();
            this.visibilityStateListeners = {};
            this.currentVisibilityState = {hidden: false};
            this.publicState = {}; //For Corvid API, we don't want to do it in real comp state to avoid renders
            return {
                enableVideo: true
            };
        },

        updatePublicState(stateUpdate) {
            const state = this.publicState;
            const {
                volume = state.volume,
                duration = state.duration,
                muted = state.isMuted,
                currentTime = state.currentTime
            } = stateUpdate;
            const isPlaying = stateUpdate.playbackState ? stateUpdate.playbackState === consts.playbackTypes.PLAYING : state.isPlaying;
            const isMuted = muted || volume < 0;
            this.publicState = {
                isPlaying,
                currentTime,
                duration,
                volume,
                isMuted
            };

            this.props.setCompState(this.props.id, this.publicState); // Requires compStateMixin
        },

        componentDidMount() {
            this.props.mediaAspect.initFeatureDetections();
            this.registerPlayer(this.props);
        },

        componentWillUnmount() {
            this.visibilityStateListeners = {};
            this.unregisterPlayer(this.props);
        },

        componentDidUpdate(prevProps) {
            this.handleVideoPropsChange(prevProps);
        },

        /**
         * Register video to aspect if there is a video and inline video playback is allowed
         * @param props
         */
        registerPlayer(props) {
            const mediaData = getMediaData(props);
            if (props.canVideoPlayInline && _.get(mediaData, 'type') === 'WixVideo' && _.includes(props.renderParts.media.video, 'video')) {
                const registrationOptions = {
                    playerType: 'mediaPlayer',
                    mediaData,
                    services: {
                        viewport: {
                            callback: this.onViewportChange,
                            eventTypes: ['in', 'out']
                        },
                        visibility: {
                            callback: this.onVisibilityChange
                        },
                        fileAvailability: {
                            callback: this.onFileAvailability,
                            videoParts: [{
                                name: 'video',
                                quality: mediaData.qualities[0].quality
                            }, {
                                name: 'storyboard',
                                quality: 'storyboard'
                            }]
                        }
                    }
                };

                props.mediaAspect.registerPlayer(props.id, registrationOptions);
            }
        },

        /**
         * Remove player from aspect
         * @param props
         */
        unregisterPlayer(props) {
            props.mediaAspect.unregisterPlayer(props.id);
        },

        /**
         * Update a new partial state to current player state by playerId
         * @param props
         * @param state
         */
        updatePlayerState(props, state) {
            props.mediaAspect.updatePlayerState(props.id, state);
            this.updatePublicState(state);
        },

        /**
         * Update quality state for a videoId
         * @param props
         * @param videoId
         * @param state
         */
        updateQualityState(props, videoId, state) {
            props.mediaAspect.updateQualityState(videoId, state);
        },

        /**
         * get player state object from for current player
         * @returns {*}
         */
        getPlayerState() {
            return this.props.mediaAspect.getData(this.props.id);
        },

        // --- Event Handlers

        /**
         * automaticly play a video when it loads if needed
         * @param props
         */
        handleAutoplay(props) {
            const isAutoPlay = props.compProp.autoplay && props.isPlayingAllowed;
            const shouldPlay = this.isInViewport && props.canVideoPlayInline;
            if (shouldPlay && isAutoPlay) {
                this._playMedia({persist: true});
            }
        },

        /**
         * Handle video re-registration and update if fundamental media info changed
         * @param prevProps
         */
        handleVideoPropsChange(prevProps) {
            const props = this.props;
            const nextMediaData = getMediaData(props);
            const prevMediaData = getMediaData(prevProps);

            const currentIsEditorMode = isEditorMode(this.props);
            const prevIsEditorMode = isEditorMode(prevProps);
            const currentIsPlayingAllowed = this.props.isPlayingAllowed;
            const prevIsPlayingAllowed = prevProps.isPlayingAllowed;

            const prevPlayerState = this.getPlayerState();

            // re-register the player in media aspect with the new video id
            if (nextMediaData.videoId !== prevMediaData.videoId ||
                props.canVideoPlayInline !== prevProps.canVideoPlayInline ||
                !_.isEqual(props.renderParts.media.video, prevProps.renderParts.media.video)) {
                this.unregisterPlayer(prevProps);
                this.registerPlayer(props);
                this.setState({enableVideo: true});
                // Preserve some values for Corvid src change
                if (currentIsPlayingAllowed) {
                    this.updatePlayerState(
                        this.props,
                        {
                            previousPlaybackState: prevPlayerState.previousPlaybackState,
                            muted: prevPlayerState.muted,
                            volume: prevPlayerState.volume
                        }
                    );
                }
            }

            if (currentIsEditorMode !== prevIsEditorMode || currentIsPlayingAllowed !== prevIsPlayingAllowed) {
                if (currentIsEditorMode) {
                    this.notifyVisibilityState({in: this.isInViewport});
                    this.stopPreviewMedia();
                } else {
                    this.resetPlaybackState(props);
                    this.notifyVisibilityState({in: this.isInViewport});
                    this.handleAutoplay(props);
                }
            }
        },

        resetPlaybackState(props) {
            const isAutoplay = props.compProp.autoplay && props.isPlayingAllowed;
            this.updatePlayerState(props, {
                playbackState: isAutoplay ? '' : consts.playbackTypes.READY,
                previousPlaybackState: isAutoplay ? consts.playbackTypes.PLAYING : ''
            });
        },

        videoVisibilityChangeHandler() {
            const params = this.currentVisibilityState;
            const currentState = this.getPlayerState();

            if (!params.hidden && params.in) {
                if (currentState.previousPlaybackState === consts.playbackTypes.PLAYING) {
                    this.updatePlayerState(this.props, {
                        playbackPausedByService: false
                    });
                    this._playMedia();
                } else if ((currentState.playbackState === '' && currentState.previousPlaybackState === '') ||
                    currentState.playbackState === consts.playbackTypes.LOADING || currentState.playbackState === consts.playbackTypes.READY) {
                    this.handleAutoplay(this.props);
                }
            } else if (currentState.previousPlaybackState === consts.playbackTypes.PLAYING) {
                this.updatePlayerState(this.props, {
                    playbackPausedByService: true
                });
                this._pauseMedia();
            }

            this.notifyVisibilityState(params);
        },

        /**
         * Updates from viewport service changes
         * @param {{
                in: boolean,
                inPartialAbove: boolean,
                inPartialBelow: boolean,
                inCover: boolean,
                inContained: boolean,
                out: boolean,
                outAbove: boolean,
                outBelow: boolean,
                percentVisible: number 0..1,
                percentOfViewport: number 0..1,
                isFixed: boolean
            }} params
         */
        onViewportChange(params) {
            const shouldUpdateVideoState = this.props.canVideoPlayInline && !isEditorMode(this.props) && !params.isFixed;
            this.isInViewport = !!params.in;

            Object.assign(this.currentVisibilityState, params);

            if (shouldUpdateVideoState) {
                this.videoVisibilityChangeHandler();
            }
        },

        addVisibilityStateListener(callback) {
            const id = _.uniqueId('MPVisibilityCallback');

            this.visibilityStateListeners[id] = callback;

            return () => {
                delete this.visibilityStateListeners[id];
            };
        },

        notifyVisibilityState(state) {
            Object.keys(this.visibilityStateListeners)
                .forEach(key => this.visibilityStateListeners[key](state));
        },

        /**
         * Updates from Page Visibility API service changes
         * @param {{hidden: boolean}} params
         */
        onVisibilityChange(params) {
            const shouldUpdateVideoState = this.props.canVideoPlayInline && !isEditorMode(this.props);

            Object.assign(this.currentVisibilityState, params);

            if (shouldUpdateVideoState) {
                this.videoVisibilityChangeHandler();
            }
        },

        /**
         * Updates form file availability (quality) service changes
         * @param {{videoId: string, availabilityState: string, type: string, readyQualities: array}} params
         */
        onFileAvailability(params) {
            const {readyQualities} = params;
            switch (params.type) {
                case 'video':
                    if (readyQualities.length) {
                        this.updateQualityState(this.props, params.videoId, {readyQualities, error: ''});
                    }
                    this.updatePlayerState(this.props, {
                        mediaReadyState: params.availabilityState
                    });
                    break;
                case 'storyboard':
                    this.updateQualityState(this.props, params.videoId, {
                        storyboardQuality: readyQualities,
                        storyboardReadyState: readyQualities.length ? consts.availabilityReadyStates.IDLE : ''
                    });
                    break;
            }
        },

        /**
         * Updates from media changes
         * @param stateEvent
         */
        onMediaStateChange(stateEvent) {
            if (!this.props.canVideoPlayInline) {
                return;
            }
            const state = this.mediaStateChangeLogic(stateEvent, this.getPlayerState());
            if (!_.isEmpty(state)) {
                this.updatePlayerState(this.props, state);
            }
        },

        // --- Logics

        /**
         * Handle player event messaging
         * @param {{type, playbackState, currentTime, duration, muted, volume, error, progress}} playerStateEvent Event from player
         * @param {{playbackState, previoudPlaybackState, currentTime, duration, playbackPausedByService}} currentState Current store values
         * @returns {{}}
         */
        mediaStateChangeLogic(playerStateEvent, currentState) {
            let state = {};
            const {volume = 1} = currentState;

            switch (playerStateEvent.type) {
                case consts.eventTypes.MOUNT:
                    state = Object.assign(_.omit(playerStateEvent, 'type'), {volume});
                    break;

                // Video load events - just fill the store with values
                case consts.eventTypes.LOAD:
                    state = _.omit(playerStateEvent, ['type', 'originalEventType']);
                    if (playerStateEvent.playbackState === consts.playbackTypes.READY) {
                        state.mediaReadyState = consts.availabilityReadyStates.IDLE;
                        this.handleAutoplay(this.props);
                    }
                    break;

                // Playback state changes (play, pause, seek etc.)
                case consts.eventTypes.PLAYSTATE:
                    switch (playerStateEvent.playbackState) {
                        //make sure playhead is on the end of the video (Safari doesnt fire this state when expected)
                        case consts.playbackTypes.PLAY_ENDED:
                            state = {
                                playbackState: consts.playbackTypes.PLAY_ENDED,
                                previousPlaybackState: ''
                            };
                            this.handleAction(ACTION_TYPES.ON_ENDED);
                            break;
                        case consts.playbackTypes.PLAYING:
                            state = {
                                playbackState: playerStateEvent.playbackState,
                                mediaReadyState: consts.availabilityReadyStates.IDLE
                            };
                            this.handleAction(ACTION_TYPES.ON_PLAY);
                            break;
                        case consts.playbackTypes.PAUSED:
                            state = {
                                playbackState: playerStateEvent.playbackState,
                                mediaReadyState: consts.availabilityReadyStates.IDLE
                            };
                            this.handleAction(ACTION_TYPES.ON_PAUSE);
                            break;
                        // On any other update, update playbackState
                        default:
                            state = {
                                playbackState: playerStateEvent.playbackState,
                                mediaReadyState: consts.availabilityReadyStates.IDLE
                            };
                    }
                    break;
                case consts.eventTypes.ERROR:
                    const videoId = getMediaData(this.props).videoId;
                    if (playerStateEvent.error === consts.errorTypes.NO_HLS_VIDEO) {
                        this.updateQualityState(this.props, videoId, {fallback: true});
                    } else if (playerStateEvent.error === consts.errorTypes.NO_VIDEO_FOUND) {
                        state = {mediaReadyState: consts.availabilityReadyStates.IN_PROCESS};
                    } else if (playerStateEvent.error === consts.errorTypes.WEBGL_ERROR) {
                        this.setState({enableVideo: false});
                    }
                    break;
                case consts.eventTypes.VOLUME:
                    // do not notify while animating and don't update volume 0 if volume is negative in state ('soft mute')
                    if (_.isNumber(this.volumeAnimation.value) || currentState.volume < 0) {
                        break;
                    }
                    // do not notify on play preview
                    if (currentState.playbackState === consts.playbackTypes.PLAY_PREVIEW) {
                        break;
                    }

                    state = {
                        volume: playerStateEvent.volume,
                        muted: playerStateEvent.muted
                    };

                    break;
                // Skip rate state
                case consts.eventTypes.RATE:
                    // TODO - Tom B. 20/12/2016
                    break;
                // On timeUpdate only update public state (for Corvid API). skip updating real state
                case consts.eventTypes.TIME_UPDATE:
                    this.updatePublicState({currentTime: playerStateEvent.currentTime});
                    this.handleAction(ACTION_TYPES.ON_PROGRESS);
                    break;
            }
            return state;
        },

        /**
         * Propagate API calls to media element through components hierarchy
         * @param {string} command
         * @param {Array<*>} params
         */
        mediaCommandsLogic(command, params = {}) {
            if (!this.props.canVideoPlayInline || !this.mediaAPI) {
                return;
            }
            const {callback} = params;
            const currentState = this.getPlayerState();
            const isPlaying = currentState.playbackState === consts.playbackTypes.PLAYING;
            const isSeeking = _.includes([
                consts.playbackTypes.SEEK_PLAYING,
                consts.playbackTypes.SEEK_PAUSED,
                consts.playbackTypes.SEEKING
            ], currentState.playbackState);
            switch (command) {
                case 'togglePlay':
                    if (isPlaying) {
                        this._pauseMedia({persist: true, callback});
                    } else {
                        this._playMedia({persist: true, callback});
                    }
                    break;

                case 'play': {
                    let {time = -1} = params;
                    if (currentState.playbackState === consts.playbackTypes.PLAY_ENDED) {
                        if (this.props.compProp.playerInteraction.allowReplay === false) {
                            break;
                        }

                        if (time < 0) {
                            time = 0;
                        }
                    }

                    if (time >= 0) {
                        // if params, seek and then play
                        this.seekMedia(time);
                    }
                    this.mediaAPI('play', {callback});

                    break;
                }
                case 'preview':
                    this.updatePlayerState(this.props, {
                        playbackState: consts.playbackTypes.PLAY_PREVIEW
                    });
                    this.mediaAPI('mute');
                    this.mediaAPI('play');
                    break;
                case 'pause': {
                    const {time = -1} = params;
                    this.mediaAPI('pause', {callback});

                    if (time >= 0) {
                        // if params, pause and then seek
                        this.seekMedia(time);
                    }
                    break;
                }
                case 'seekStart':
                    if (isSeeking) {
                        break;
                    }

                    this.updatePlayerState(this.props, {
                        playbackState: isPlaying ? consts.playbackTypes.SEEK_PLAYING : consts.playbackTypes.SEEK_PAUSED
                    });

                    if (isPlaying) {
                        this._pauseMedia();
                    }
                    break;

                case 'seekEnd': {
                    const {time} = params;

                    //todo: when seeking to the end of video safari shows black frame
                    this.seekMedia(time);
                    break;
                }
                case 'stop':
                    this.updatePlayerState(this.props, {
                        playbackState: consts.playbackTypes.STOPPING,
                        previousPlaybackState: consts.playbackTypes.PAUSED
                    });
                    this.mediaAPI('stop', {callback});
                    break;

                case 'mute':
                case 'unMute':
                    this.mediaAPI(command, {callback});
                    this.updatePlayerState(this.props, {
                        volume: Math.abs(currentState.volume)
                    });
                    break;

                case 'volumeFadeOut': {
                    const {volume, muted} = currentState;
                    if (muted) {
                        break;
                    }
                    const {duration} = params;
                    if (this.volumeAnimation.animation) {
                        this.props.animations.kill(this.volumeAnimation.animation);
                    }
                    this.volumeAnimation.value = this.volumeAnimation.value || volume;
                    this.volumeAnimation.animation = this.props.animations.animate('BaseObjectProps', this.volumeAnimation, duration, 0, {
                        value: 0,
                        ease: 'Sine.easeOut',
                        callbacks: {
                            onStart: () => {
                                this.updatePlayerState(this.props, {
                                    volume: -volume // in soft mute
                                });
                            },
                            onUpdate: () => {
                                this.mediaAPI('setVolume', {volume: this.volumeAnimation.value});
                            },
                            onComplete: () => {
                                this.volumeAnimation = getVolumeAnimationDefault();
                                if (callback) {callback();}
                            }
                        }
                    });
                    break;
                }
                case 'volumeFadeIn': {
                    const {volume, muted} = currentState;
                    if (muted) {
                        break;
                    }
                    const {duration} = params;
                    if (this.volumeAnimation.animation) {
                        this.props.animations.kill(this.volumeAnimation.animation);
                    }
                    this.volumeAnimation.value = this.volumeAnimation.value || 0;
                    this.volumeAnimation.animation = this.props.animations.animate('BaseObjectProps', this.volumeAnimation, duration, 0, {
                        value: Math.abs(volume),
                        ease: 'Sine.easeIn',
                        callbacks: {
                            onStart: () => {
                                this.updatePlayerState(this.props, {
                                    volume: Math.abs(volume)
                                });
                            },
                            onUpdate: () => {
                                this.mediaAPI('setVolume', {
                                    volume: this.volumeAnimation.value
                                });
                            },
                            onComplete: () => {
                                this.volumeAnimation = getVolumeAnimationDefault();
                                if (callback) {callback();}
                            }
                        }
                    });
                    break;
                }
                default:
                    this.mediaAPI(command, params);
            }
        },

        /**
         * returns the mute state considering autoplay policy and editor state
         * todo - move all editor stuff and preview flows to preview extension
         * @param props
         * @param currentState
         * @returns {boolean}
         */
        getMuteValueByAutoPlayPolicy(props, currentState) {
            const playingStates = [consts.playbackTypes.PLAYING, consts.playbackTypes.PLAY_PREVIEW, consts.playbackTypes.SEEK_PLAYING];


            //playing preview from editor  panel - cross iframe stack  call violation - always mute
            if (isEditorMode(this.props) && _.includes(playingStates, currentState.playbackState)) {
                return true;
            }

            // viewer - if playback state exists always take mute from it
            if (!isEditorMode(this.props) && !_.isUndefined(currentState.muted)) {
                return currentState.muted;
            }

            //initial state by autoplay policy
            if (props.compProp.autoplay && (_.isUndefined(currentState.muted) || isEditorMode(this.props))) {
                return true;
            }
            const isMobile = props.isMobileDevice || props.isMobileView;
            const playerInHoverInteraction = props.compProp.playerInteraction.rollIn !== 'none';
            if (!isMobile && playerInHoverInteraction) {
                return true;
            }
            return false;
        },

        // --- API functions

        /**
         * API to register the video, will do nothing if inline video is not allowed
         * @param mediaAPI
         */
        setMediaAPI(mediaAPI) {
            this.mediaAPI = mediaAPI;
        },

        togglePlayMedia(callback) {
            this.mediaCommandsLogic('togglePlay', {callback});
        },
        playMedia(time, callback) {
            this._playMedia({persist: true, time, callback});
        },
        previewMedia(callback) {
            this.notifyVisibilityState({in: true, forcePlay: true});
            this.mediaCommandsLogic('preview', {callback});
        },
        pauseMedia(time, callback) {
            this._pauseMedia({persist: true, time, callback});
        },
        stopMedia(callback) {
            this._stopMedia({persist: true, callback});
        },
        stopPreviewMedia(callback) {
            this._stopMedia({persist: true, callback, reset: true});
        },
        setMediaVolume(volume) {
            this.mediaCommandsLogic('setVolume', {volume});
        },
        muteMedia(callback) {
            this.mediaCommandsLogic('mute', {callback});
        },
        unMuteMedia(callback) {
            this.mediaCommandsLogic('unMute', {callback});
        },
        mediaVolumeFadeOut(duration, callback) {
            this.mediaCommandsLogic('volumeFadeOut', {duration, callback});
        },
        mediaVolumeFadeIn(duration, callback) {
            this.mediaCommandsLogic('volumeFadeIn', {duration, callback});
        },
        seekMedia(time) {
            this.mediaCommandsLogic('seek', {time});
        },
        startSeekMedia(time) {
            this.mediaCommandsLogic('seekStart', {time});
        },
        endSeekMedia(time) {
            this.mediaCommandsLogic('seekEnd', {time});
        },

        _playMedia({persist, time, callback} = {}) {
            if (persist) {
                this.updatePlayerState(
                    this.props,
                    {previousPlaybackState: consts.playbackTypes.PLAYING}
                );
            }
            this.mediaCommandsLogic('play', {time, callback});
        },
        _pauseMedia({persist, time, callback} = {}) {
            if (persist) {
                this.updatePlayerState(
                    this.props,
                    {previousPlaybackState: consts.playbackTypes.PAUSED}
                );
            }
            this.mediaCommandsLogic('pause', {time, callback});
        },
        _stopMedia({persist, callback, reset} = {}) {
            if (persist) {
                this.updatePlayerState(
                    this.props,
                    {previousPlaybackState: consts.playbackTypes.STOPPED}
                );
            }
            this.mediaCommandsLogic('stop', {callback, reset});
        },

        /**
         * Create a balata component
         * @param {{
         *       styleId: string,
         *       id: string,
         *       compData: object,
         *       bgStyle: object,
         *       onClick: function,
         *       compStaticBehaviors: object,
         *       compDesign: object
         *  }} [propsOverrides]
         *
         * @returns {ReactCompositeComponent}
         */
        createFillLayers(propsOverrides) {
            const props = _.assign({}, this.props, propsOverrides);
            const currentState = this.getPlayerState();
            const {volume = 1} = currentState;
            const skinData = {
                skin: 'skins.viewer.balata.balataBaseSkin',
                styleId: props.styleId + consts.balataConsts.BALATA
            };

            props.compProp.mute = this.getMuteValueByAutoPlayPolicy(props, currentState);
            props.compProp.volume = Math.abs(volume);

            const mediaData = getMediaData(props);
            const bgData = getBackgroundData(props);
            const extraProps = {
                ref: consts.balataConsts.BALATA,
                id: props.id + consts.balataConsts.BALATA,
                animations: props.animations,
                parentId: props.id,
                style: _.assign({}, consts.defaultMediaStyle, {width: '100%', height: '100%'}, props.styleOverrides),
                compBehaviors: props.compStaticBehaviors,
                compProp: props.compProp,
                compDesign: props.compDesign,
                rotationInDegrees: props.rotationInDegrees,
                onClick: props.onClick,
                notifyMediaState: this.onMediaStateChange,
                registerStateChange: (listenerId, callback) => this.props.mediaAspect.registerStateChange(listenerId, this.props.id, callback),
                unregisterStateChange: listenerId => this.props.mediaAspect.unregisterStateChange(listenerId, this.props.id),
                setMediaAPI: this.setMediaAPI,
                enableVideo: props.enableVideo && props.enableMaskedVideo && this.state.enableVideo,
                shouldRenderSrc: props.shouldRenderSrc,
                imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
                isPlayingAllowed: this.props.isPlayingAllowed,
                isEditorMode: isEditorMode(props),
                //layers rendering by existing data
                renderParts: props.renderParts,
                playbackFormat: props.playbackFormat,
                playbackConfig: props.playbackConfig,
                playbackUrl: props.playbackUrl,
                playerStyle: this.props.style,
                getIsVisible: this.props.mediaAspect.shouldPlay.bind(this.props.mediaAspect, this.props.id),
                addVisibilityStateListener: this.addVisibilityStateListener,
                addWebGLContext: this.props.mediaAspect.webglContexts.add,
                removeWebGLContext: this.props.mediaAspect.webglContexts.remove,
                mask: props.mask,
                maskPosterFallback: props.maskPosterFallback,
                isMediaPlayer: true
            };

            if (this.props.shouldRenderSrc) {
                const mediaDimensions = bgUtils.convertStyleToDimensions(_.pick(props.style, ['width', 'height']));
                extraProps.mediaDimensions = mediaDimensions;
                extraProps.imageUrlPreMeasureParams = bgUtils.getImageUrlPreMeasureParams(mediaDimensions, getImageData(mediaData), bgData.fittingType, bgData.alignType, props.isMobileView, props.isInSeo);
            }
            return this.createChildComponent(
                props.compData,
                'wysiwyg.viewer.components.background.Balata',
                skinData,
                extraProps
            );
        }
    };
});
