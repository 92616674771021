/**
 روح بلط البحر
 */

define([
    'prop-types',
    'lodash',
    'coreUtils',
    'backgroundCommon',
    'componentsCore',
    'santa-components'
], function (
    PropTypes,
    _,
    coreUtils,
    backgroundCommon,
    componentsCore,
    santaComponents
) {
    'use strict';

    const {MEDIA, OVERLAY, UNDERLAY, MEDIA_PARAMS, MEDIA_PADDING_PARAMS, OVERLAY_PARAMS, COLOR_BG_PARAMS} = coreUtils.mediaConsts.balataConsts;
    const autoClip = useWebkitClipPath =>
        useWebkitClipPath ? {WebkitClipPath: 'inset(0)'} : {clip: 'rect(0,auto,auto,0)'};
    const FORCE_COMPOSITE = {transform: 'translateZ(0px)'};
    const stringifyBoolean = bool => bool ? 'true' : '';
    /**
     * Create extra style info based on component transformations object
     * @param {'overlay'|'media'|'underlay'} layerName balata layer
     * @param {object} data BackgroundMedia item
     * @param {object} state this.state
     * @returns {{}}
     */
    function getLayerCssTransforms(layerName, data, state) {
        const transformsData = data[coreUtils.mediaConsts.balataConsts[layerName]] || {};
        const transformsState = _.get(state, ['transforms', layerName], {});
        let transforms = _.merge({}, transformsData, transformsState);

        const style = {};
        if (transforms.opacity) {
            style.opacity = transforms.opacity;
            transforms = _.omit(transforms, 'opacity');
        }
        if (!_.isEmpty(transforms)) {
            style.transform = _.reduce(transforms, function (transform, value, key) {
                transform += `${key}(${value}) `;
                return transform;
            }, '');
        }

        return style;
    }

    function getMediaStyle(props, bgEffectName, isFullScreenHeight, isFullScreenAndFixed) {
        return {
            position: isFullScreenAndFixed ? 'fixed' : 'absolute',
            pointerEvents: bgEffectName ? 'none' : 'auto',
            width: '100%',
            height: isFullScreenHeight ? '100vh' : '100%',
            top: 0,
            left: 0
        };
    }

    /**
     * Create a bgMedia component with current data
     * @param {object} props this.props
     * @param {object} state this.state
     * @param {object} data
     * @param {string?} bgEffectName an optional scroll effect name
     * @param {boolean} isFullScreenHeight
     * @returns {ReactCompositeComponent}
     */
    function getMediaComponent(props, state, data, bgEffectName, isFullScreenHeight, isFullScreenAndFixed) {
        const skinPartData = {
            skin: MEDIA_PARAMS.skin,
            styleId: MEDIA_PARAMS.style
        };
        const style = _.assign(
            getMediaStyle(props, bgEffectName, isFullScreenHeight, isFullScreenAndFixed),
            getLayerCssTransforms(MEDIA, data, state),
            props.designDataChangeAspect.cssScaleTransition
        );
        const extraProps = {
            key: MEDIA_PARAMS.ref,
            style,
            id: props.id + MEDIA_PARAMS.ref,
            parentId: props.parentId,
            ref: MEDIA_PARAMS.ref,
            bgEffectName,
            isFullScreenHeight,
            mediaDimensions: props.mediaDimensions,
            containerStyle: props.containerStyle,
            filterEffect: data.filterEffect,
            fittingType: data.fittingType,
            alignType: data.alignType,
            mediaTransforms: data.mediaTransforms,
            enableVideo: props.enableVideo,
            notifyMediaState: props.notifyMediaState,
            registerStateChange: props.registerStateChange,
            unregisterStateChange: props.unregisterStateChange,
            renderParts: props.renderParts,
            playbackFormat: props.playbackFormat,
            playbackConfig: props.playbackConfig,
            playbackUrl: props.playbackUrl,
            setMediaAPI: props.setMediaAPI,
            compProp: props.compProp,
            isPlayingAllowed: props.isPlayingAllowed,
            isEditorMode: props.isEditorMode,
            shouldRenderSrc: props.shouldRenderSrc,
            imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
            playerStyle: props.playerStyle,
            getIsVisible: props.getIsVisible,
            addVisibilityStateListener: props.addVisibilityStateListener,
            addWebGLContext: props.addWebGLContext,
            removeWebGLContext: props.removeWebGLContext,
            rootId: props.rootId,
            maskPosterFallback: props.maskPosterFallback,
            isFullScreenAndFixed: props.isFullScreenAndFixed
        };
        return [data.mediaRef, MEDIA_PARAMS.comp, skinPartData, extraProps];
        // return this.createChildComponent(data.mediaRef, MEDIA_PARAMS.comp, skinPartData, extraProps);
    }

    /**
     * Create a bgOverlay component type overlay
     * @param {object} props this.props
     * @param {object} state this.state
     * @param {object} data BackgroundMedia data item
     * @param {string?} bgEffectName an optional scroll effect name
     * @returns {ReactCompositeComponent}
     */
    function getOverlayComponent(props, state, data, isFullScreenAndFixed) {
        const skinPartData = {
            skin: OVERLAY_PARAMS.skin,
            styleId: OVERLAY_PARAMS.style
        };

        const layerCssTransforms = getLayerCssTransforms(OVERLAY, data, state);
        //overlay layer disappear on Chrome when component is flipped
        const shouldForceComposite = !layerCssTransforms.transform && _(props.style).get('transform', '').includes('scale');

        const extraProps = {
            key: OVERLAY_PARAMS.ref,
            style: _.assign(getLayerCssTransforms(OVERLAY, data, state), shouldForceComposite ? FORCE_COMPOSITE : null),
            colorOverlay: data.colorOverlay,
            colorOverlayOpacity: data.colorOverlayOpacity,
            imageOverlay: data.imageOverlay,
            isFullScreenAndFixed,
            blendMode: _.get(data.overlayBlending, 'blendMode'),
            blendModeOpacity: _.get(data.overlayBlending, 'opacity'),
            id: props.id + OVERLAY_PARAMS.ref,
            ref: OVERLAY_PARAMS.ref
        };

        return [null, OVERLAY_PARAMS.comp, skinPartData, extraProps];
        //return this.createChildComponent(null, OVERLAY_PARAMS.comp, skinPartData, extraProps);
    }

    /**
     * Create a bgOverlay component type underlay
     * @param {object} props this.props
     * @param {object} state this.state
     * @param {object} data BackgroundMedia data item
     * @param {string?} bgEffectName an optional scroll effect name
     * @returns {ReactCompositeComponent}
     */
    function getUnderlayComponent(props, state, data, isFullScreenAndFixed) {
        const skinPartData = {
            skin: COLOR_BG_PARAMS.skin,
            styleId: COLOR_BG_PARAMS.style
        };

        const extraProps = {
            style: getLayerCssTransforms(UNDERLAY, data, state),
            colorOverlay: data.color,
            colorOverlayOpacity: getUnderlayOpacityByType(data.colorOpacity, data.mediaRef),
            isFullScreenAndFixed,
            id: props.id + COLOR_BG_PARAMS.ref,
            ref: COLOR_BG_PARAMS.ref
        };

        return [null, COLOR_BG_PARAMS.comp, skinPartData, extraProps];
        //return this.createChildComponent(null, COLOR_BG_PARAMS.comp, skinPartData, extraProps);
    }

    /**
     * Create a layer to wrap media and overlay
     * @param {object} props this.props
     * @param {string?} bgEffectName an optional scroll effect name
     * @param {boolean} isFullScreenHeight an optional scroll effect name
     * @param {array<ReactCompositeComponent>} mediaChildren array with media and optional overlay
     * @returns {ReactCompositeComponent}
     */
    function getMediaPaddingLayer(props, isFullScreenHeight, mediaChildren) {
        const top = _.get(props.compProp.mediaBackgroundPadding, 'top', 0);
        const bottom = _.get(props.compProp.mediaBackgroundPadding, 'bottom', 0);
        const wrapProps = {
            key: MEDIA_PADDING_PARAMS.ref,
            id: props.id + MEDIA_PADDING_PARAMS.ref,
            ref: MEDIA_PADDING_PARAMS.ref,
            style: {
                position: 'absolute',
                width: '100%',
                height: `calc(100% - ${top}px - ${bottom}px)`,
                top,
                bottom
            }
        };

        _.assign(wrapProps.style, getClip(props, isFullScreenHeight));
        return santaComponents.utils.createReactElement('div', wrapProps, mediaChildren);
    }

    /**
     * Get diffenrent opacity by data type
     * Basically - we want to have no bg for videos
     * @param {number} colorOpacity
     * @param {object|null} mediaRef
     * @returns {number}
     */
    function getUnderlayOpacityByType(colorOpacity, mediaRef) {
        if (!mediaRef || mediaRef.type !== 'WixVideo' && mediaRef.type !== 'Video') { // eslint-disable-line no-mixed-operators
            return colorOpacity;
        }
        return 0;
    }

    /**
     * TODO: "showOverlayForMediaType" is a temp solution for old garbage overlay data on images, till we have a new bg overlay product
     * TODO: See https://jira.wixpress.com/browse/CLNT-7518
     * @param data
     * @returns {boolean}
     */
    function shouldRenderOverlay(data) {
        const media = data.mediaRef;
        const hasOverlay = data.imageOverlay || data.colorOverlay;
        const showOverlayForMediaType = data.showOverlayForMediaType || 'WixVideo';
        const shouldRender = media && (showOverlayForMediaType === 'all' || showOverlayForMediaType === media.type);

        return !!(hasOverlay && shouldRender);
    }


    function getBgData({compDesign, compData}) {
        return _.get(compDesign, 'background', _.get(compData, 'background', {}));
    }

    function getClip(props, isFullScreenHeight) {
        const shouldClip = shouldClipMedia(props, isFullScreenHeight);
        return shouldClip ? autoClip(props.clipParallaxWithWebkitClipPath()) : {};
    }

    function isMediaPadding(props) {
        const padding = _.get(props.compProp, 'mediaBackgroundPadding', {});
        const {top = 0, bottom = 0} = padding;
        const isEnabled = props.enableBackgroundPadding;
        const hasValue = top || bottom;
        const HasValueOrInEditorMode = hasValue || props.componentViewMode === 'editor';
        return isEnabled && HasValueOrInEditorMode;
    }

    function shouldClipMedia(props, isFullScreenHeight) {
        // Never clip mediaPlayer video
        const {isMediaPlayer} = props;
        return !isMediaPlayer && isFullScreenHeight;
    }

    /**
        * Change key when moving between editor and preview to unmount the media (instead of clearing animations and media info)
        * @returns {string}
        */
    function getKey({bgEffectName, enableVideo, isEditorMode}) {
        const video = enableVideo ? 'video' : 'no_video';
        const playback = isEditorMode ? 'no_playback' : 'playback';
        return bgEffectName ? `balata_${playback}_${video}` : 'balata';
    }

    const balata = {
        displayName: 'Balata',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.defaults(
            {
                id: PropTypes.string.isRequired,
                parentId: PropTypes.string.isRequired,
                compData: PropTypes.object,
                compDesign: PropTypes.object,
                compProp: PropTypes.object,
                compBehaviors: PropTypes.array,
                rotationInDegrees: PropTypes.number,
                style: PropTypes.object,
                onClick: PropTypes.func,
                playerStyle: PropTypes.object,
                isDesktopDevice: santaComponents.santaTypesDefinitions.Device.isDesktopDevice.isRequired,
                isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
                componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode.isRequired,
                designDataChangeAspect: santaComponents.santaTypesDefinitions.SiteAspects.designDataChangeAspect.isRequired,
                clipParallaxWithWebkitClipPath: santaComponents.santaTypesDefinitions.BrowserFlags.clipParallaxWithWebkitClipPath,
                fixedBackgroundColorBalata: santaComponents.santaTypesDefinitions.BrowserFlags.fixedBackgroundColorBalata.isRequired,
                renderType: santaComponents.santaTypesDefinitions.PublicModel.renderType,
                isPlayingAllowed: PropTypes.PropTypes.bool,
                isEditorMode: PropTypes.PropTypes.bool,
                enableVideo: PropTypes.PropTypes.bool,
                enableBackgroundPadding: PropTypes.PropTypes.bool,
                notifyMediaState: PropTypes.PropTypes.func,
                registerStateChange: PropTypes.PropTypes.func,
                unregisterStateChange: PropTypes.PropTypes.func,
                setMediaAPI: PropTypes.PropTypes.func,
                playbackFormat: PropTypes.PropTypes.string,
                playbackConfig: PropTypes.PropTypes.object,
                playbackUrl: PropTypes.PropTypes.string,
                renderParts: PropTypes.PropTypes.object,
                getIsVisible: PropTypes.PropTypes.func,
                addVisibilityStateListener: PropTypes.PropTypes.func,
                addWebGLContext: PropTypes.PropTypes.func,
                removeWebGLContext: PropTypes.PropTypes.func,
                mask: PropTypes.PropTypes.object,
                maskPosterFallback: PropTypes.PropTypes.object,
                bgEffectName: PropTypes.string,
                isFullScreenHeight: PropTypes.PropTypes.bool,
                isFullScreenAndFixed: PropTypes.PropTypes.bool
            },
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(backgroundCommon.components.bgMedia),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(backgroundCommon.components.bgOverlay)
        ),
        getInitialState() {
            return {
                transforms: {}
            };
        },
        UNSAFE_componentWillReceiveProps(nextProps) {
            this.handleDesignDataBehaviors(nextProps);
        },

        /**
         * Get the internal components
         * @param {object} props this.props
         * @param {object} state this.state
         * @param {object} data mediaRef
         * @returns {Array<ReactCompositeComponent>}
         */
        getBalataLayers(data) {
            const {bgEffectName, isFullScreenHeight, isFullScreenAndFixed} = this.props;
            const backgroundStructure = [];
            const mediaStructure = [];

            const underlayProps = getUnderlayComponent(this.props, this.state, data, isFullScreenAndFixed);
            backgroundStructure.push(this.createChildComponent(...underlayProps));

            if (!_.isEmpty(data.mediaRef)) {
                const mediaProps = getMediaComponent(this.props, this.state, data, bgEffectName, isFullScreenHeight, isFullScreenAndFixed);
                mediaStructure.push(this.createChildComponent(...mediaProps));
            }
            if (shouldRenderOverlay(data)) {
                const overlayProps = getOverlayComponent(this.props, this.state, data, isFullScreenAndFixed);
                mediaStructure.push(this.createChildComponent(...overlayProps));
            }

            if (!isMediaPadding(this.props)) {
                return backgroundStructure.concat(mediaStructure);
            }

            backgroundStructure.push(getMediaPaddingLayer(this.props, isFullScreenHeight, mediaStructure));
            return backgroundStructure;
        },

        handleDesignDataBehaviors(nextProps) {
            const nextBgData = getBgData(nextProps);
            const prevBgData = getBgData(this.props);

            // clear transforms state
            this.setState({
                transforms: {}
            });

            // detect design data change (which will change the state if needed)
            if (prevBgData.id !== nextBgData.id) {
                const bgAspect = this.props.designDataChangeAspect;
                bgAspect.notify(this.props.parentId, this.props.compDesign, nextProps.compDesign);
            }
        },

        /**
         * return the dataset properties needed for layout
         * @param backgroundDesignData
         * @param bgEffectName
         * @returns {object}
         */
        getLayoutData(backgroundDesignData, isSmoothScroll) {
            return {
                'data-page-id': this.props.rootId,
                'data-enable-video': stringifyBoolean(this.props.enableVideo),
                'data-bg-effect-name': this.props.bgEffectName || '',
                'data-media-type': _.get(backgroundDesignData, ['mediaRef', 'type'], ''),
                'data-use-clip-path': this.props.clipParallaxWithWebkitClipPath() || '',
                'data-needs-clipping': (!isSmoothScroll && shouldClipMedia(this.props, this.props.isFullScreenHeight)) || '',
                'data-render-type': this.props.renderType
            };
        },

        /**
         * Set some transforms on balata root to fix some oddities
         * 1. force composite for border radius (mix-blend-mode is not working without it)
         * 2. chrome has a bug with masks inside rotated elements, setting any rotation other than 0 on the masked element fixes it (#WEED-21308)
         * @returns {object} an object with a transforms string or an empty object
         */
        getTransforms() {
            const transforms = [];
            const hasBorderRadius = _.get(this.props.compDesign, ['cssStyle', 'cssBorderRadius']);
            if (hasBorderRadius) {
                transforms.push('translateZ(0)');
            }
            if (this.props.mask && this.props.rotationInDegrees) {
                transforms.push('rotate(0.01deg)');
            }
            return transforms.length ? {transform: transforms.join(' ')} : {};
        },

        getSkinProperties() {
            const data = getBgData(this.props);
            const backgroundChildren = _.isEmpty(data) ? null : this.getBalataLayers(data);
            const clip = getClip(this.props, this.props.isFullScreenHeight);

            const style = _.assign(
                {
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    pointerEvents: this.props.bgEffectName ? 'none' : 'auto'
                },
                clip,
                this.getTransforms(),
                this.props.style,
                this.props.mask
            );

            const refData = {
                '': _.assign({
                    style,
                    children: backgroundChildren,
                    key: getKey(this.props)
                }, this.getLayoutData(data, this.props.isExperimentOpen('bv_smoothScroll')))
            };

            if (this.props.onClick) {
                refData[''].onClick = this.props.onClick;
            }

            return refData;
        },

        getDefaultSkinName() {
            return 'skins.viewer.balata.balataBaseSkin';
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.background.Balata', balata);

    return balata;
});
